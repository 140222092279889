import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns';

import type { ColDef, ColGroupDef, ExcelDataType, GridApi, ValueFormatterParams } from 'ag-grid-community'
import type { Stock } from 'api-client'

interface DownloadGridProps {
  downloadData: Stock[]
  onApply: (api: GridApi | undefined) => void
}

export function DownloadGrid({ downloadData, onApply }: DownloadGridProps) {
  const dateFormatter = useCallback((params: ValueFormatterParams<Date | null>) => {
    if (!params.value) {
      return ''
    }
    return format(params.value, 'yyyy-MM-dd')
  }, [downloadData])
  
  const columnDefs = useMemo(
    (): (ColDef | ColGroupDef)[] => [
      { headerName: 'プロダクト統一企業ID', children: [{ headerName: 'company_id', field: 'companyId' }] },
      { headerName: 'プロダクト統一センターID', children: [{ headerName: 'center_id', field: 'centerId' }] },
      { headerName: '自社センターID', children: [{ headerName: 'org_center_id', field: 'orgCenterId' }] },
      { headerName: 'センター名', children: [{ headerName: 'center_name', field: 'centerName' }] },
      {
        headerName: '社内商品コード',
        children: [{ headerName: 'org_item_code', field: 'orgItemCode', cellClass: 'itemCode' }],
      },
      { headerName: '商品名称', children: [{ headerName: 'item_name', field: 'itemName' }] },
      { headerName: '商品コード', children: [{ headerName: 'item_code', field: 'itemCode', cellClass: 'itemCode' }] },
      { headerName: '仕入先企業ID', children: [{ headerName: 'org_supplier_id', field: 'orgSupplierId', cellClass: 'strData' }] },
      { headerName: '実績日', children: [{ headerName: 'rec_date', field: 'recDate', valueFormatter: dateFormatter }] },
      { headerName: '発注日', children: [{ headerName: 'order_date', field: 'orderDate', valueFormatter: dateFormatter }] },
      { headerName: '週番号', children: [{ headerName: 'week_no', field: 'weekNo' }] },
      { headerName: '実績・予定区分', children: [{ headerName: 'date_type', field: 'dateType' }] },
      { headerName: '入荷数', children: [{ headerName: 'arrival_quantity', field: 'arrivalQuantity' }] },
      { headerName: '出荷数', children: [{ headerName: 'ship_quantity', field: 'shipQuantity' }] },
      { headerName: '在庫数', children: [{ headerName: 'stock_quantity', field: 'stockQuantity' }] },
      { headerName: '在庫日数', children: [{ headerName: 'stock_days', field: 'stockDays' }] },
      { headerName: '発注実績数', children: [{ headerName: 'order_quantity_rec', field: 'orderQuantityRec' }] },
      { headerName: '受注実績数', children: [{ headerName: 'salesorder_quantity', field: 'salesorderQuantity' }] },
      { headerName: '在庫実績数', children: [{ headerName: 'stock_quantity_rec', field: 'stockQuantityRec' }] },
      {
        headerName: '発注加減要素',
        children: [{ headerName: 'order_quantity_reason', field: 'orderQuantityReason', cellClass: 'strData' }],
      },
      { headerName: '出荷数変更可能', children: [{ headerName: 'enable_update_ship', field: 'enableUpdateShip' }] },
      { headerName: '入荷数変更可能', children: [{ headerName: 'enable_update_arrival', field: 'enableUpdateArrival' }] },
      { headerName: '入荷数変更済', children: [{ headerName: 'is_updated_arrival', field: 'isUpdatedArrival' }] },
      { headerName: '出荷数変更済', children: [{ headerName: 'is_updated_ship', field: 'isUpdatedShip' }] },
      { headerName: '出荷予測数', children: [{ headerName: 'ship_quantity_expected', field: 'shipQuantityExpected' }] },
      {
        headerName: '予測算出日時',
        children: [{ headerName: 'ship_expected_calc_datetime', field: 'shipExpectedCalcDatetime', valueFormatter: dateFormatter }],
      },
      { headerName: '予測ランク', children: [{ headerName: 'expected_rank', field: 'expectedRank' }] },
      {
        headerName: '非参加企業出荷数合計',
        children: [{ headerName: 'general_customer_ship_quantity', field: 'generalCustomerShipQuantity' }],
      },
      {
        headerName: '得意先発注推奨数',
        children: [{ headerName: 'customer_order_quantity_recommend', field: 'customerOrderQuantityRecommend' }],
      },
      {
        headerName: '出荷予定数',
        children: [{ headerName: 'ship_quantity_scheduled', field: 'shipQuantityScheduled' }],
      },
      {
        headerName: '発注推奨値',
        children: [{ headerName: 'order_quantity_recommend', field: 'orderQuantityRecommend' }],
      },
      {
        headerName: '発注推奨算出日時',
        children: [{ headerName: 'order_recommend_calc_datetime', field: 'orderRecommendCalcDatetime', valueFormatter: dateFormatter  }],
      },
      {
        headerName: '前日末在庫数',
        children: [{ headerName: 'stock_quantity_rec_morning', field: 'stockQuantityRecMorning' }],
      },
      { headerName: '目標在庫日数', children: [{ headerName: 'stock_days_rank', field: 'stockDaysRank' }] },
      { headerName: '目標欠品率', children: [{ headerName: 'safety_stock_rank', field: 'safetyStockRank' }] },
      { headerName: '更新日時', children: [{ headerName: 'update_datetime', field: 'updateDatetime', valueFormatter: dateFormatter  }] },
      { headerName: '出荷可能日', children: [{ headerName: 'enable_ship', field: 'enableShip' }] },
      { headerName: '入荷可能日', children: [{ headerName: 'enable_arrival', field: 'enableArrival' }] },
      {
        headerName: '仕入先受注可能日',
        children: [{ headerName: 'supplier_enable_order', field: 'supplierEnableOrder' }],
      },
      { headerName: '発注業務可能日', children: [{ headerName: 'enable_order', field: 'enableOrder' }] },
      {
        headerName: '仕入先プロダクト統一企業ID',
        children: [{ headerName: 'supplier_company_id', field: 'supplierCompanyId' }],
      },
      { headerName: '仕入先企業名', children: [{ headerName: 'supplier_name', field: 'supplierName' }] },
      { headerName: '発注単位コード', children: [{ headerName: 'order_unit_type', field: 'orderUnitType' }] },
      { headerName: '発注単位', children: [{ headerName: 'order_unit_type_name', field: 'orderUnitTypeName' }] },
      { headerName: 'ケースロット数', children: [{ headerName: 'case_lot', field: 'caseLot' }] },
      { headerName: '最低発注量', children: [{ headerName: 'min_order_quantity', field: 'minOrderQuantity' }] },
      { headerName: 'リードタイム', children: [{ headerName: 'order_leadtime', field: 'orderLeadtime' }] },
      { headerName: '締め時間', children: [{ headerName: 'order_limit_time', field: 'orderLimitTime' }] },
      { headerName: 'メーカー名', children: [{ headerName: 'maker_name', field: 'makerName' }] },
      { headerName: '発売日', children: [{ headerName: 'sales_startdate', field: 'salesStartdate', valueFormatter: dateFormatter  }] },
      { headerName: '終了日', children: [{ headerName: 'sales_enddate', field: 'salesEnddate', valueFormatter: dateFormatter  }] },
      { headerName: '規格', children: [{ headerName: 'spec', field: 'spec' }] },
      { headerName: 'ケースサイズ 長手 (mm)', children: [{ headerName: 'longside', field: 'longside' }] },
      { headerName: 'ケースサイズ 短手 (mm)', children: [{ headerName: 'shortside', field: 'shortside' }] },
      { headerName: 'ケースサイズ 高さ (mm)', children: [{ headerName: 'height', field: 'height' }] },
      { headerName: 'ケースサイズ 重量 (g)', children: [{ headerName: 'weight', field: 'weight' }] },
      {
        headerName: 'パレット最大積付数',
        children: [{ headerName: 'max_pallet_quantity', field: 'maxPalletQuantity' }],
      },
      {
        headerName: 'パレット積載数（面）',
        children: [{ headerName: 'pallet_quantity_face', field: 'palletQuantityFace' }],
      },
      {
        headerName: 'パレット積載数（段）',
        children: [{ headerName: 'pallet_quantity_tier', field: 'palletQuantityTier' }],
      },
      { headerName: 'JANコード（バラ）', children: [{ headerName: 'jan', field: 'jan', cellClass: 'itemCode' }] },
      {
        headerName: 'JANコード（ケース）',
        children: [{ headerName: 'jan_case', field: 'janCase', cellClass: 'itemCode' }],
      },
      {
        headerName: 'GTINコード（バラ）',
        children: [{ headerName: 'gtin_13', field: 'gtin13', cellClass: 'itemCode' }],
      },
      {
        headerName: 'GTINコード（ケース）',
        children: [{ headerName: 'gtin_14', field: 'gtin14', cellClass: 'itemCode' }],
      },
      { headerName: 'ITFコード', children: [{ headerName: 'itf', field: 'itf', cellClass: 'itemCode' }] },
    ],
    []
  )
  const excelStyles = useMemo(
    () => [
      {
        id: 'itemCode',
        dataType: 'String' as ExcelDataType,
      },
      {
        id: 'strData',
        dataType: 'String' as ExcelDataType,
      },
    ],
    []
  )
  const [rowData, setRowData] = useState<Stock[]>([])
  const gridRef = useRef<AgGridReact>(null)

  const onModelUpdated = () => {
    const gridApi = gridRef.current?.api
    if (gridApi && rowData.length > 0) {
      onApply(gridApi)
    }
  }

  useEffect(() => {
    setRowData(downloadData)
  }, [downloadData])

  return (
    <div>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        onModelUpdated={onModelUpdated}
        excelStyles={excelStyles}
      />
    </div>
  )
}
