export const productTitle = 'Hacobu PSI'

// 権限区分
export const AuthType = {
  ReadOnly: '0',
  General: '1',
  Admin: '9',
} as const

// ヘッダーグルーピング区分
export const RecType = {
  Rec: 'rec',
  Expected: 'expected',
  Today: 'today',
} as const

export const initRequestParam = {
  datacount: 0,
  maxcount: 0,
  lastUpdateDatetime: null,
  list: [],
}
export const initRequestParamWithDaylist = {
  ...initRequestParam,
  daylist: [],
}

export const ERROR_RATE_LABEL = '誤差率'
export const STOCK_DAYS_LABEL = '在庫日数'
