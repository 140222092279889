import React from 'react'

import { Box, Typography } from '@mui/material'

function Faq() {
  return (
    <Box>
      <Typography component="div" variant="largeBold">
        FAQ
      </Typography>
      現在メンテナンス中です
    </Box>
  )
}

export default Faq
